import * as React from "react";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const RaceSection = ({ location }) => {
  const t = "קטע מספר 5: מבאר רבבה לפארק אשכול (גן לאומי הבשור)";
  return (
    <Layout path={location.pathname} title={t}>
      <SectionContent title={t}>
        <div>
          <p>
            <strong>אורך הקטע: 9.5 ק&quot;מ</strong>
          </p>
          <p>
            <strong>רמת הקושי: קלה &#8211; בינונית</strong>
          </p>
          <p>
            <strong>תיאור הקטע</strong>:
          </p>
          <p>
            חוזרים מבאר רבבה ל 'דרך נוף הבשור', ופונים בה שמאלה. רצים לאורך דרך
            הנוף עד לסיומה לפני המפגש עם כביש 241.
          </p>
          <p>
            <strong>טופוגרפיה</strong>: גבעות מתגלגלות עם מגמת ירידה מתונה
            <strong>.</strong>
          </p>
          <p>
            <strong>מסלול</strong>: דרך לבנה.
          </p>
          <p>
            <strong>בטיחות</strong>: ערנות לרכבים הנוסעים בדרך הנוף.
          </p>
          <p>
            <strong>תחנת החלפה</strong> : מתחת לגשר אברהם. יורדים מכביש 241 בדרך
            המובילה ל'דרך נוף הבשור'. לבאים מכיוון מערב (צומת מעון), יורדים
            מהכביש בשלט המכוון לדרך הנוף, אך במקום לפנות ימינה לדרך, ממשיכים ישר
            לכיוון הגשר. לבאים מכיוון מזרח (צומת אורים) פונים מיד לאחר הגשר
            ימינה לדרך לבנה המתעקלת לכיוון הגשר.
          </p>
        </div>
        <div>
          <p>
            <iframe
              src="//www.gpsies.com/mapOnly.do?fileId=yolmticvacystbya"
              width="100%"
              height="400"
              scrolling="no"
              title="sectionIframe"
            ></iframe>
          </p>
        </div>
      </SectionContent>
    </Layout>
  );
};

export default RaceSection;
